<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage Settings</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Settings
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn @click="createSetting"   class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add new
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Name</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr  v-for="item in settings">
                    <td class="px-2">
                      <a @click="editSetting(item)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.name }}
                      </a>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editSetting(item)" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit Setting</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a  @click="deleteSetting(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="settings.length == 0">
                    <td colspan="2" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination :disabled="isLoading"
                            v-if="settings.length > 0"
                            class="pull-right mt-7"
                            @input="getAllSettings"
                            v-model="page"
                            :total-rows="total"
                            :per-page="perPage"
                            first-number
                            last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog  v-model="dialog"
               fullscreen
               hide-overlay
               transition="dialog-bottom-transition"
               scrollable>
      <v-card>
        <v-toolbar dark>
          <v-card-title>
            <span>{{title}} Setting {{edit? '('+setting.name+')':''}}</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="12" v-if="!edit">
                <v-text-field
                    outlined
                    dense :error="$v.setting.name.$error"
                    label="Name*"
                    v-model="setting.name">
                </v-text-field>
                <span class="text-danger" v-if="$v.setting.name.$error">This information is required</span>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-select :items="data_types" item-text="text" item-value="value"
                    outlined
                    dense :error="$v.setting.data_type.$error"
                    label="Data Type*"
                    v-model="setting.data_type">
                </v-select>
                <span class="text-danger" v-if="$v.setting.data_type.$error">This information is required</span>
              </v-col>
              <v-col cols="12" sm="6" md="12" v-if="setting.data_type=='input' || setting.data_type=='' || setting.data_type==null">
                <v-text-field
                    outlined
                    dense :error="$v.setting.value.$error"
                    label="Value*"
                    v-model="setting.value">
                </v-text-field>
                <span class="text-danger" v-if="$v.setting.value.$error">This information is required</span>
              </v-col>
              <v-col cols="12" sm="6" md="12" v-if="setting.data_type=='number'">
                <v-text-field
                    outlined type="number"
                    dense :error="$v.setting.value.$error"
                    label="Value*"
                    v-model="setting.value">
                </v-text-field>
                <span class="text-danger" v-if="$v.setting.value.$error">This information is required</span>
              </v-col>
              <v-col  md="8" v-if="setting.data_type=='file'">
                <v-file-input outlined  prepend-icon="" dense @change="previewImage"
                              prepend-inner-icon="mdi-file" label="File"
                              v-model="setting.value">

                </v-file-input>
                <span class="text-danger" v-if="$v.setting.value.$error">This information is required</span>
              </v-col>
              <v-col md="4" v-if="setting.data_type=='file' && file!=null">
                <v-img
                      :lazy-src="file"
                      max-height="120"
                      max-width="140"
                      class="img-thumbnail"
                      :src="file"
                ></v-img>
              </v-col>

              <v-col cols="12" sm="6" md="12" v-if="setting.data_type=='textarea'">
                <ckeditor  :config="editorConfig"  v-model="setting.value">

                </ckeditor>
                <span class="text-danger" v-if="$v.setting.value.$error">This information is required</span>
              </v-col>
              <v-col cols="12" sm="6" md="12" v-if="setting.data_type=='date'">

                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="setting.value"
                        label="Date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs" dense outlined
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker no-title
                      v-model="setting.value"
                      @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="btn btn-primary"
              dark
              medium
              @click="createOrUpdate()">
            Save
          </v-btn>
          <v-btn
              color="btn btn-standard"
              dark
              medium
              @click="closeDialog()"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import SettingService from "@/services/setting/SettingService";
import {required, requiredIf} from "vuelidate/lib/validators";
const setting=new SettingService;
export default {
  validations:{
    setting:{
      name:{
        required: requiredIf(function (nestedModel){
          return !this.edit;
        }),
      },
      value:{required},
      data_type:{required}
    }
  },
  data(){
    return{
      settings:[],
      search:{

      },
      total: null,
      perPage: null,
      page: null,
      isLoading:false,
      dialog:false,
      title:'',
      edit:false,
      setting:{
        name:'',
        value:'',
        data_type:'',
      },
      data_types:[
        {value:'file',text:'File'},
        {value:'textarea',text:'Textarea'},
        {value:'input',text:'Input'},
        {value:'number',text:'Number'},
        {value:'date',text:'Date'},
      ],
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
      menu:false,
      file:null,
    }
  },
  methods:{
    getAllSettings(){
      setting
          .paginate(this.search, this.page)
          .then(response => {
            this.settings=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading=false;
          })
          .catch((err) => {

          });
    },
    createSetting(){
      this.dialog=true;
      this.title='Add new';
      this.edit=false;
    },
    editSetting(item){
      this.setting=item;
      this.dialog=true;
      this.title='Edit';
      this.edit=true;

      if(this.setting && this.setting.data_type=='file' && this.setting.file_path){
        this.file=this.setting.file_path.original;
      }
    },
    closeDialog(){
      this.dialog=false;
      this.setting={
            name:'',
            value:'',
            data_type:'',
      },
          this.file=null
    },
    deleteSetting(item){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            setting
                .delete(item)
                .then((response) => {
                  this.getAllSettings();
                  this.$snotify.success("Setting deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    createOrUpdate(){
      this._create();
    },
    resetSetting(){
      this.setting={
        name:'',
        value:'',
      }
      this.$v.$reset();
      this.edit=false;
      this.closeDialog();
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.setting) {
        if (key === "value" && this.setting[key] != null && this.setting[key] != undefined) {
          formData.append('value', this.setting[key]);
        }else {
          if(this.setting[key]){
            formData.append(key,this.setting[key]);
          }
        }
      }
      return formData;
    },
    _create: function(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        let formData=this.convertToFormData();
        setting
            .create(formData)
            .then(response => {
              this.getAllSettings();
              if(this.edit){
                this.$snotify.success("Setting updated");
              }else{
                this.$snotify.success("Setting added");
              }
              this.resetSetting();

            })
            .catch((err) => {
              this.errors = err.response.data.errors;
              this.$snotify.error("Oops something went wrong");
            });
      }
    },
    previewImage(){
      if(this.setting.data_type=='file' && this.setting.value != null){
        this.file= URL.createObjectURL(this.setting.value)
      }else{
        this.file = null;
      }
    },

  },
  mounted() {
    this.getAllSettings();
  }
}
</script>